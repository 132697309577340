import PostApi from 'piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi'

export default class CSVExportService {
	static ExportBotUsers(type: string, board_key: string): void {
		window.open(`${ PostApi.getRequestUrl(type, 'ExportBotUsers') }?board_key=${ board_key }`, '_black')
	}

	static ExportCashRegistries(type: string, board_key: string): void {
		window.open(`${ PostApi.getRequestUrl(type, 'ExportCashRegistries') }?board_key=${ board_key }`, '_black')
	}

	static ExportProducts(type: string, board_key: string): void {
		window.open(`${ PostApi.getRequestUrl(type, 'ExportProducts') }?board_key=${ board_key }`, '_black')
	}

	static ExportSales(type: string, board_key: string): void {
		window.open(`${ PostApi.getRequestUrl(type, 'ExportSales') }?board_key=${ board_key }`, '_black')
	}
}
