











import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import PageTitle from '@/components/PageTitle.vue'

import Shop from 'piramis-base-components/src/components/Shop/Shop.vue'
import { ShopConfig } from 'piramis-base-components/src/components/Shop/types'
import { ShopService } from '@/includes/services/ShopService'
import CSVExportService from '@/includes/services/CSVExportService'

import Currencies from 'piramis-base-components/src/components/Shop/Currencies'
import CashRegisterHelper from './CashRegisterHelper'
import { cloneDeep } from 'lodash'
import ProductsHelper from '@/views/shop/ProductsHelper'

@Component({
  components: {
    PageTitle,
    Shop,
  }
})
export default class NewShop extends Vue {
  config: ShopConfig | null = null

  async created(): Promise<void> {
    if (!this.$store.state.shopState.cashRegisters) {
      await this.$store.dispatch('getCashRegisters')
    }
    if (!this.$store.state.shopState.products) {
      await this.$store.dispatch('getProducts')
    }

    this.config = {
      paymentSystemsCore: new CashRegisterHelper(cloneDeep(this.$store.state.shopState.cashRegisters)),
      productsCore: new ProductsHelper(Currencies, cloneDeep(this.$store.state.shopState.products)),
      getNotices: (offset, limit) => new Promise(resolve => {
        ShopService.getNotices('tg', { board_key: this.$store.state.boardsState.activeBoard!.board, offset, limit })
          .then(({ items }) => resolve(items))
      }),
      exportTables: {
        products: () => CSVExportService.ExportProducts('tg', this.$store.state.boardsState.activeBoard!.board),
        cashRegisters: () => CSVExportService.ExportCashRegistries('tg', this.$store.state.boardsState.activeBoard!.board),
        sales: () => CSVExportService.ExportSales('tg', this.$store.state.boardsState.activeBoard!.board),
      },
      getPreview: (image) => `https://api.presscode.app/cabinet/v1/tg/getfilepreview?file_descriptor=${ image }&target=${ this.$store.state.boardsState.activeBoard!.board }`,
      getReports: () => new Promise(resolve => {
        ShopService.getProductsReport('tg', { board_key: this.$store.state.boardsState.activeBoard!.board })
          .then(({ items }) => resolve(items))
      })
    }
  }
}
